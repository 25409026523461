@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

html {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

body {
  margin: 0;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

.bullet-list_item {
  & p {
    margin-block-start: 5px;
    margin-block-end: 5px;
  }
}
